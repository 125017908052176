import jQuery from "jquery";
const auth = require('./configurator/auth');

var profile = {
    load: function () {
        jQuery.ajax({
            type: 'POST',
            url: getProfile,
            data: {},
            success(result) {
                jQuery("input[name=name]").val(result.name);
                jQuery("input[name=surname]").val(result.surname);
                jQuery("input[name=email]").val(result.email);
                jQuery("input[name=address]").val(result.address);
                jQuery("input[name=city]").val(result.city);
                jQuery("input[name=cap]").val(result.cap);
                jQuery("input[name=state]").val(result.state);
                jQuery("input[name=country]").val(result.country);
                jQuery("input[name=phone]").val(result.phone);

                var retailer = false;
                for (var i = 0; i < auth.status.user.roles.length; i++) {
                    if (auth.status.user.roles[i].name === 'Retailer') {
                        jQuery("input[name=company]").val(result.company);
                        jQuery("input[name=vat]").val(result.vat);
                        jQuery("input[name=distributor_mail]").val(result.distributor_mail);
                        retailer = true;
                    }
                }

                if (!retailer) {
                    jQuery("input[name=company]").removeAttr("required");
                    jQuery("input[name=vat]").removeAttr("required");
                    jQuery("input[name=distributor_mail]").removeAttr("required");
                    jQuery(".retailer_fields").hide();
                }
            },
            error(result) {
                swal({
                    title: translations.profile,
                    text: result.responseJSON.message,
                    icon: 'error',
                }).then(function () {
                    window.location.href = goToSketchbook;
                });
            },
            statusCode: {
                419: function() {
                    auth.token(function () {
                        profile.load();
                    });
                }
            },
        });
    },
    event: function () {
        jQuery("#save_profile").click(function () {
            profile.save();
        });

        jQuery(".delete_profile").click(function () {
            profile.delete();
        });

        window.addEventListener('onLoggedOut', function (e) {
            window.location.href = goToSketchbook;
        });
    },
    save: function () {
        if (!jQuery('.config-profile__form')[0].checkValidity()) {
            jQuery('.config-profile__form')[0].reportValidity();
            return false;
        }
        jQuery.ajax({
            type: 'POST',
            url: saveProfile,
            data: jQuery('.config-profile__form').serialize(),
            success(result) {
                if (result.success) {
                    swal({
                        title: translations.save_profile,
                        text: result.message,
                        icon: 'success',
                    });
                }
            },
            error(jqXHR, textStatus) {
                var message = [];
                for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                    message.push(element.join(", "));
                }
                swal({
                    title: translations.save_profile,
                    text: message.join("\n"),
                    icon: 'error',
                });
            }
        });
    },
    delete: function () {
        swal({
            title: translations.attention,
            text: translations.are_you_sure_delete,
            icon: 'warning',
            dangerMode: true,
            buttons: {
                cancel: translations.cancel,
                continue: {
                    text: translations.yes_delete,
                    value: "ok",
                },
            },
        }).then(value => {
            if (value) {
                jQuery.ajax({
                    type: 'POST',
                    url: deleteProfile,
                    data: {},
                    success(result) {
                        if (result.success) {
                            swal({
                                title: translations.delete_profile,
                                text: result.message,
                                icon: 'success',
                            }).then(function () {
                                window.location.href = myFogherHome;
                            });
                        }
                    },
                    error(jqXHR, textStatus) {
                        var message = [];
                        for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                            message.push(element.join(", "));
                        }
                        swal({
                            title: translations.delete_profile,
                            text: message.join("\n"),
                            icon: 'error',
                        });
                    }
                });
            } else {

            }
        });
    }
}

if (typeof token !== "undefined" && jQuery('body').hasClass("user-profile")) {
    auth.token(function () {
        auth.getLoggedUser(function (result) {
            if (result.isLoggedIn) {
                profile.load();
            } else {
                window.location.href = goToSketchbook;
            }
        }, function () {
            window.location.href = goToSketchbook;
        });
    });

    profile.event();
}
