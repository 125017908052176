//PLUGINS STYLES
import 'slick-carousel/slick/slick.scss';
// import '@fancyapps/fancybox/src/css/core.css'
// import '@fancyapps/fancybox/src/css/thumbs.css'
//PLUGINS
import 'bootstrap';
import jQuery from 'jquery';
window.jQuery = jQuery;
window.$ = jQuery;
// import { gsap } from './gsap';
// import {ScrollTrigger} from "./gsap/ScrollTrigger";
// gsap.registerPlugin(ScrollTrigger);
import slick from "slick-carousel/slick/slick";
// import SmoothScroll from './smooth-scroll';
//INIT SCROLL
// const ss=new SmoothScroll({el:document.querySelector("[data-scroll-container]"),smooth:window.activeSmooth,getDirection:!0});
// ss.on("scroll",ScrollTrigger.update),ScrollTrigger.scrollerProxy(document.querySelector("[data-scroll-container]"),{scrollTop(e){return arguments.length?ss.scrollTo(e,0,0):ss.scroll.instance.scroll.y},getBoundingClientRect:()=>({top:0,left:0,width:window.innerWidth,height:window.innerHeight}),pinType:document.querySelector("[data-scroll-container]").style.transform?"transform":"fixed"}),ScrollTrigger.addEventListener("refresh",()=>ss.update()),ScrollTrigger.refresh(),ss.on("scroll",e=>{});
//FANCY
// require("@fancyapps/fancybox");
//SELECT2
// require("jquery-mousewheel/jquery.mousewheel");
import * as select2 from "select2";
// require('select2/src/scss/core.scss');
//PAGE SPECIFIC
require('./site-menu.js');
// require('./util-pages');
// require('./product-detail');
require('./product-list');
require('./downloads');
// require('./landing');
// require('./landing-grevil');
// require('./landing-pinarello-f');
// require('./landing-pinarello-x');
// require('./landing-nytro-e');
require('./home');
// require('./frame-reg');
require('./myfogher/configurator');
require('./myfogher/sketchbook');
require('./store-locator');
require('./myfogher/kitchen-detail');
require('./myfogher/profile');
//require('./myfogher/view3d');
// require('./wallet/list');
// require('./wallet/transfer');

const swal = require('sweetalert');

//********* COMMON JS FOR ALL *********//

/**
 * Debounce
 * @param func
 * @param timeout
 * @returns {(function(...[*]): void)|*}
 */
export function debounce (func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

/**
 * ScrollTop
 * @param el
 * @param value
 * @returns {*}
 */
export function scrollTop (el, value) {
    if (value === undefined) {
        return el.pageYOffset;
    } else {
        if (el === window || el.nodeType === 9) {
            el.scrollTo(el.pageXOffset, value);
        } else {
            el.pageYOffset = value;
        }
    }
}

export function parents(el, selector) {
    const parents = [];
    while ((el = el.parentNode) && el !== document) {
        if (!selector || el.matches(selector)) parents.push(el);
    }
    return parents;
}

export function next(el, selector) {
    const nextEl = el.nextElementSibling;
    if (!selector || (nextEl && nextEl.matches(selector))) {
        return nextEl;
    }
    return null;
}

/**
 * Parallax
 * @type {NodeListOf<Element>}
 */
const parallaxItems = document.querySelectorAll(".parallax");
if (parallaxItems.length) {
    // Force to start page on top
    if (history.scrollRestoration) {
		history.scrollRestoration = 'manual';
	} else {
		window.onbeforeunload = function () {
			window.scroll(0, 0);
		}
	}
    document.addEventListener("DOMContentLoaded", () => {
        let options = {
            root: null,
            rootMargin: "0px 0px",
            threshold: 0.05,
        };
        let observer = new IntersectionObserver(beTouching, options);
        parallaxItems.forEach(item => {
            observer.observe(item);
        });
    });

    function beTouching(entries, ob) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add("active");
                //ob.unobserve(entry.target);
            } else {
                entry.target.classList.remove("active");
            }
        });
    }

    const handleParallaxScroll = () => {
        let parallaxItems = document.querySelectorAll('.parallax.active .parallax__item');
        parallaxItems.forEach((item) => {
            let dataParallax;
            if (item.parentElement.classList.contains('parallax--scroll-top-window')) {
                dataParallax = '-' + scrollTop(window) / item.dataset.parallax + 'px';
            } else {
                dataParallax = item.getBoundingClientRect().top / item.dataset.parallax + 'px';
            }
            item.style.transform = 'translate3d(0, ' + dataParallax + ', 0)';
        });
    }
    const onScroll = debounce(() => handleParallaxScroll(), 6);
    window.addEventListener("scroll", onScroll);
}

/**
 * Touch Points
 * @type {NodeListOf<Element>}
 */
const touchPoints = document.querySelectorAll('.touch-point');
if (touchPoints.length) {
    const touchPointsClose = () => {
        touchPoints.forEach(touchPoint => {
            touchPoint.classList.remove('show');
            const button = touchPoint.querySelector('.touch-point__button');
            const modal = touchPoint.querySelector('.touch-point__modal');
            button.setAttribute('aria-expanded', 'false');
            button.classList.remove('show');
            modal.classList.remove('show');
        });
    }
    touchPoints.forEach(touchPoint => {
        const button = touchPoint.querySelector('.touch-point__button');
        const modal = touchPoint.querySelector('.touch-point__modal');
        const close = touchPoint.querySelector('.btn-close');
        button.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            touchPointsClose();
            e.target.parentNode.classList.toggle('show');
            e.target.classList.toggle('show');
            e.target.setAttribute(
                'aria-expanded',
                button.getAttribute('aria-expanded') === 'true'
                    ? 'false'
                    : 'true'
            );
            modal.classList.toggle('show');
        });
        close.addEventListener('click', (e) => {
            e.preventDefault();
            touchPointsClose();
        });
    });
    document.addEventListener('click', e => {
        e.stopImmediatePropagation();
        if( !e.target.closest('.touch-point' )
            || e.target.classList.contains('touch-point__backdrop')
        ) {
            touchPointsClose();
        }
    });
}



//PRODUCT DETAIL
if (jQuery('.accessories-slider, .technologies-slider, .stories__slider, .appliances-slider, .atelier-slider').length > 0) {

    let accessoriesCarousel = jQuery('.accessories-slider, .technologies-slider, .appliances-slider, .atelier-slider').slick({
        arrows: true,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
        ]
    });

    let storiesCarousel = jQuery('.stories__slider').slick({
        arrows: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        variableWidth: true,
        adaptiveHeight: true
    });

}

// slider cucine Agher

$('.slider-single').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    adaptiveHeight: true,
    infinite: false,
    useTransform: true,
    speed: 400,
    cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
    asNavFor: '.slider-nav-agher'
});

$('.slider-nav-agher')
    .on('init', function(event, slick) {
        $('.slider-nav-agher .slick-slide.slick-current').addClass('is-active');
    })
    .slick({
        slidesToShow: 7,
        slidesToScroll: 1,
        focusOnSelect: false,
        infinite: false,
        arrows: false,
        dots: false,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                dots: true,
            }
        }, {
            breakpoint: 769,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: true,
            }
        }, {
            breakpoint: 420,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true,
            }
        }]
    });


// $('.filtering').slick({
//     slidesToShow: 4,
//     slidesToScroll: 4,
//     arrows: false,
//     infinite: false,
//     cssEase: 'linear',
//     // autoplay: true,
//     // autoplaySpeed: 2000
// });
//
// var filtered = false;
//
// $('.js-filter').on('click', function(){
//     if (filtered === false) {
//         $('.filtering').slick('slickFilter','div:contains("table")');
//         $(this).text('Unfilter Slides');
//         filtered = true;
//     } else {
//         $('.filtering').slick('slickUnfilter');
//         $(this).text('Filter Slides');
//         filtered = false;
//     }
// });
//
// var but = document.querySelector("[name='nojara-btn']");
// setInterval(function () {but.click();},3000);


$('.slider-single').on('afterChange', function(event, slick, currentSlide) {
    $('.slider-nav-agher').slick('slickGoTo', currentSlide);
    var currrentNavSlideElem = '.slider-nav-agher .slick-slide[data-slick-index="' + currentSlide + '"]';
    $('.slider-nav-agher .slick-slide.is-active').removeClass('is-active');
    $(currrentNavSlideElem).addClass('is-active');
});

$('.slider-nav-agher').on('click', '.slick-slide', function(event) {
    event.preventDefault();
    var goToSingleSlide = $(this).data('slick-index');

    $('.slider-single').slick('slickGoTo', goToSingleSlide);
});


// bottoni gres chiaro e scuro
$(document).ready(function () {
    $('.gres-chiaro-gas-tagliere').click(function(){
        $('.gres-render-gas-tagliere').attr('src','/assets/images/cucina-agher/slider-render-top/agher-gas-tagliere-chiara@2x.webp');
    });
    $('.gres-scuro-gas-tagliere').click(function(){
        $('.gres-render-gas-tagliere').attr('src','/assets/images/cucina-agher/slider-render-top/agher-gas-tagliere-scura@2x.webp');
    });
    $('.gres-chiaro-lavello-induzione').click(function(){
        $('.gres-render-lavello-induzione').attr('src','/assets/images/cucina-agher/slider-render-top/agher-induzione-lavello-chiara@2x.webp');
    });
    $('.gres-scuro-lavello-induzione').click(function(){
        $('.gres-render-lavello-induzione').attr('src','/assets/images/cucina-agher/slider-render-top/agher-induzione-lavello-scura@2x.webp');
    });

    $('.gres-chiaro-lavello-gas').click(function(){
        $('.gres-render-lavello-gas').attr('src','/assets/images/cucina-agher/slider-render-top/agher-lavello-gas-chiara.webp');
    });
    $('.gres-scuro-lavello-gas').click(function(){
        $('.gres-render-lavello-gas').attr('src','/assets/images/cucina-agher/slider-render-top/agher-lavello-gas-scura@2x.webp');
    });

    $('.gres-chiaro-lavello-tagliere').click(function(){
        $('.gres-render-lavello-tagliere').attr('src','/assets/images/cucina-agher/slider-render-top/agher-lavello-tagliere-chiara@2x.webp');
    });
    $('.gres-scuro-lavello-tagliere').click(function(){
        $('.gres-render-lavello-tagliere').attr('src','/assets/images/cucina-agher/slider-render-top/agher-lavello-tagliere-scura@2x.webp');
    });
    $('.gres-chiaro-2-taglieri').click(function(){
        $('.gres-render-2-taglieri').attr('src','/assets/images/cucina-agher/slider-render-top/agher-tagliere-tagliere-chiara@2x.webp');
    });
    $('.gres-scuro-2-taglieri').click(function(){
        $('.gres-render-2-taglieri').attr('src','/assets/images/cucina-agher/slider-render-top/agher-tagliere-tagliere-scura@2x.webp');
    });
    $('.gres-chiaro-induzione-tagliere').click(function(){
        $('.gres-render-induzione-tagliere').attr('src','/assets/images/cucina-agher/slider-render-top/agher-induzione-tagliere-chiara@2x.webp');
    });
    $('.gres-scuro-induzione-tagliere').click(function(){
        $('.gres-render-induzione-tagliere').attr('src','/assets/images/cucina-agher/slider-render-top/agher-induzione-tagliere-scura@2x.webp');
    });

    //apri e chiudi tendina account
    $('.config__account').click(function () {console.log("1");
        if ($(this).hasClass('active')) {
            $(this).removeClass('active')
            $('.config__account-menu').fadeOut();
        } else {console.log("2");
            $(this).addClass('active')
            $('.config__account-menu').fadeIn();
        }
    });
});


/**
 * Search focus on modal opened
 * @type {Element}
 */
const searchModal = document.querySelector('#searchModal');
if (searchModal) {
    searchModal.addEventListener('shown.bs.modal', event => {
        searchModal.querySelector('.search-modal__input').focus();
    });
}



$(".video-cover").click(function(){
    $(this).fadeOut(1500);
    $('video').trigger('play');
});

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$('.btn-close').click(function() {
    $(".filter-selected").remove();
})

//LANDING NOJARA
if (jQuery('.nojara-color-opt').length > 0) {
    let nojaraCarousel = jQuery('.nojara-color-opt').slick({
        arrows: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 1500,
        fade: true,
    });
}

