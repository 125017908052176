module.exports = {
    status: {
        token: null,
        isLoggedIn: false,
        user: null,
    },
    token: function (callback) {
        return jQuery.ajax({
            type: 'GET',
            url: token+"?time="+(Date.now() / 1000 | 0),
            data: {},
            success(result) {
                jQuery.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': result
                    }
                });

                module.exports.testToken(callback);

                setTimeout(module.exports.token,1000 * 60 * 10);
            },
            error() {}
        });
    },
    testToken: function (callback) {
        return jQuery.ajax({
            type: 'POST',
            url: token+"?time="+(Date.now() / 1000 | 0),
            data: {},
            success(result) {
                if (typeof callback != "undefined") {
                    callback(result);
                }
                if (module.exports.status.token == null) {
                    const event = new CustomEvent('tokenReceived');
                    window.dispatchEvent(event);
                }

                module.exports.status.token = result;
            },
            error() {
                module.exports.token();
            }
        });
    },
    getLoggedUser: function (callback, errorCallback) {
        return jQuery.ajax({
            type: 'GET',
            url: loggedUser,
            data: {},
            success(result) {
                if (typeof callback != "undefined") {
                    callback(result);
                }
                module.exports.status.isLoggedIn = result.isLoggedIn;
                module.exports.status.user = result.user;
                module.exports.updateLayout();
            },
            error() {
                if (typeof errorCallback != "undefined") {
                    errorCallback();
                }
                module.exports.updateLayout();
            }
        });
    },
    updateLayout: function() {
        if (this.status.isLoggedIn) {
            jQuery('a.login_open').hide();
            jQuery('.loggedin-hidden').show();
            jQuery(".config__account-menu .login_menu_button").hide();
            jQuery(".user_info").text(translations.hi + ", " + this.status.user.name);
            jQuery(".config__account-menu .sketchbook_menu_button").show();
            jQuery(".config__account-menu .profile_menu_button").show();
            jQuery(".config__account-menu .downloads_menu_button").show();
            jQuery(".config__account-menu .logout_menu_button").show();
            jQuery(".config-save__sub .logged").show();
            jQuery(".config-save__sub .guest").hide();
        } else {
            jQuery('a.login_open').show();
            jQuery('.loggedin-hidden').hide();
            jQuery(".config__account-menu .login_menu_button").show();
            jQuery(".config__account-menu .user_info").hide();
            jQuery(".config__account-menu .sketchbook_menu_button").hide();
            jQuery(".config__account-menu .profile_menu_button").hide();
            jQuery(".config__account-menu .downloads_menu_button").hide();
            jQuery(".config__account-menu .logout_menu_button").hide();
            jQuery(".config-save__sub .logged").hide();
            jQuery(".config-save__sub .guest").show();
            jQuery("#loading_login").addClass("d-none");
            jQuery(".hello-user").addClass("d-none");
            jQuery("#no_login_msg").removeClass("d-none");
            jQuery("#bottone_login_loader").removeClass("d-none");
        }
    },
    loginForm: function () {
        jQuery(".login_open").click(function () {
            var tl = new gsap.timeline({
                onStart: function () {
                    jQuery('.login__error').addClass('prepare');
                }
            });
            tl.to('.login__error',{
                duration: .5,
                opacity: 1
            });
        });
        jQuery('.logout-button').click(function () {
            module.exports.logout();
        });
    },
    login: function () {
        jQuery('.config__login-form .submit').click(function () {
            if (!jQuery('.config__login-form')[0].checkValidity()) {
                jQuery('.config__login-form')[0].reportValidity();
                return false;
            }

            module.exports.loginAjax();
        });
    },
    loginAjax: function () {
        return jQuery.ajax({
            type: 'POST',
            url: login,
            data: jQuery('.config__login-form').serialize(),
            success(result) {
                if (result.success) {
                    var event = new CustomEvent('loggedNowSave');
                    window.dispatchEvent(event);

                    swal({
                        title: translations.login,
                        text: result.message,
                        icon: 'success',
                    }).then(function () {
                        var tl = new gsap.timeline({
                            onComplete: function () {
                                jQuery('.login__error').removeClass('prepare');
                            }
                        });
                        tl.to('.login__error',{
                            duration: .5,
                            opacity: 0
                        })
                        .add(function () {
                            //reset forms
                            jQuery('.config__register-form-tab').removeClass('active');
                            jQuery('.config__register-form-customerBTN').addClass('active')
                            jQuery('.config__register-form-shop').fadeOut(function () {
                                jQuery('.config__register-form-customer').fadeIn();
                            });

                            jQuery('.config__register-form').fadeOut();
                            jQuery('.config__register-form-psw').fadeOut();
                            jQuery('.config__login-form').fadeIn();
                        });
                        module.exports.token(function () {
                            const event = new CustomEvent('onLoggedIn');
                            window.dispatchEvent(event);
                        });
                        module.exports.getLoggedUser();
                    });
                }
            },
            error(jqXHR, textStatus) {
                var message = [];
                for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                    message.push(element.join(", "));
                }
                swal({
                    title: translations.login,
                    text: message.join("\n"),
                    icon: 'error',
                });
            },
            statusCode: {
                419: function() {
                    auth.token(function () {
                        module.exports.loginAjax();
                    });
                }
            }
        });
    },
    recoverPassword: function () {
        jQuery('.config__register-form-psw .submit').click(function () {
            if (!jQuery('.config__register-form-psw')[0].checkValidity()) {
                jQuery('.config__register-form-psw')[0].reportValidity();
                return false;
            }
            jQuery.ajax({
                type: 'POST',
                url: recoverPassword,
                data: jQuery('.config__register-form-psw').serialize(),
                success(result) {
                    if (result.success) {
                        swal({
                            title: translations.recover_password,
                            text: result.message,
                            icon: 'success',
                        }).then(function () {
                            jQuery('.config__register-form-psw').fadeOut(function () {
                                jQuery('.config__login-form').fadeIn();
                            });
                        });
                    }
                },
                error(jqXHR, textStatus) {
                    var message = [];
                    for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                        message.push(element.join(", "));
                    }
                    swal({
                        title: translations.recover_password,
                        text: message.join("\n"),
                        icon: 'error',
                    });
                }
            });
        });
    },
    resetValidation: function () {
        jQuery('.config__validation-resend-modal .submit').click(function () {
            if (!jQuery('.config__validation-resend-modal')[0].checkValidity()) {
                jQuery('.config__validation-resend-modal')[0].reportValidity();
                return false;
            }
            jQuery.ajax({
                type: 'POST',
                url: resendVerification,
                data: jQuery('.config__validation-resend-modal').serialize(),
                success(result) {
                    if (result.success) {
                        swal({
                            title: translations.resend_verification,
                            text: result.message,
                            icon: 'success',
                        }).then(function () {
                            jQuery('.config__validation-resend-modal').fadeOut(function () {
                                jQuery('.config__login-form').fadeIn();
                            });
                        });
                    }
                },
                error(jqXHR, textStatus) {
                    var message = [];
                    for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                        message.push(element.join(", "));
                    }
                    swal({
                        title: translations.resend_verification,
                        text: message.join("\n"),
                        icon: 'error',
                    });
                }
            });
        });
    },
    customerRegister: function () {
        jQuery('.config__register-form-customer .submit').click(function () {
            if(jQuery(this).hasClass("disabled"))
                return false;

            if (!jQuery('.config__register-form-customer')[0].checkValidity()) {
                jQuery('.config__register-form-customer')[0].reportValidity();
                return false;
            }

            jQuery(this).text("LOADING...").addClass("disabled");
            jQuery.ajax({
                type: 'POST',
                url: userRegistration,
                data: jQuery('.config__register-form-customer').serialize(),
                success(result) {
                    if (result.success) {
                        //var event = new CustomEvent('loggedNowSave');
                        //window.dispatchEvent(event);

                        swal({
                            title: translations.register,
                            text: result.message,
                            icon: 'success',
                        }).then(function () {
                            jQuery('.config__register-form').fadeOut(function () {
                                jQuery('.config__login-form').fadeIn();
                            });
                        });
                    }
                },
                error(jqXHR, textStatus) {
                    var message = [];
                    for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                        message.push(element.join(", "));
                    }
                    swal({
                        title: translations.register,
                        text: message.join("\n"),
                        icon: 'error',
                    });
                }
            });
        });
    },
    retailerRegister: function () {
        jQuery('.config__register-form-shop .submit').click(function () {
            if (!jQuery('.config__register-form-shop')[0].checkValidity()) {
                jQuery('.config__register-form-shop')[0].reportValidity();
                return false;
            }
            jQuery.ajax({
                type: 'POST',
                url: userRegistration,
                data: jQuery('.config__register-form-shop').serialize(),
                success(result) {
                    if (result.success) {
                        swal({
                            title: translations.register,
                            text: result.message,
                            icon: 'success',
                        }).then(function () {
                            jQuery('.config__register-form').fadeOut(function () {
                                jQuery('.config__login-form').fadeIn();
                            });
                        });
                    }
                },
                error(jqXHR, textStatus) {
                    var message = [];
                    for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                        message.push(element.join(", "));
                    }
                    swal({
                        title: translations.register,
                        text: message.join("\n"),
                        icon: 'error',
                    });
                }
            });
        });
    },
    logout: function () {
        return jQuery.ajax({
            type: 'GET',
            url: logout,
            data: {},
            success(result) {
                if (result.isLoggedOut) {
                    module.exports.status.isLoggedIn = false;
                    swal({
                        title: translations.logout,
                        text: result.message,
                        icon: 'success',
                    }).then(function () {
                        module.exports.token(function () {
                            const event = new CustomEvent('onLoggedOut');
                            window.dispatchEvent(event);
                        });
                        module.exports.updateLayout();
                    });
                }
            },
            error() {}
        });
    },
}

const jQuery = require('jquery')
const {gsap} = require('../../gsap')
