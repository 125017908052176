/**
 *  Desktop menu
 */
const matchMediaXlUp = window.matchMedia('(min-width: 1200px)');
const headerNavLinks = document.querySelectorAll('.header .nav-link.dropdown-toggle');
const headerDropdowns = document.querySelectorAll('.header .mega-dropdown');
const headerNavbar = document.querySelector('.header .navbar');
if (headerNavLinks.length && headerDropdowns.length && matchMediaXlUp.matches) {
    const dropdownClose = () => {
        headerDropdowns.forEach(headerDropdown => {
            headerDropdown.classList.remove('is-open');
        });
        headerNavLinks.forEach(headerNavLink => {
            headerNavLink.classList.remove('mega-dropdown-open');
        });
        if (window.scrollY > 100 || !headerNavbar.parentElement.classList.contains('has-navbar-dark')) {
            headerNavbar.classList.remove('navbar-dark');
        }
    }

    document.addEventListener('click', event => {
        const isClickInside = document.querySelector('header .navbar')?.contains(event.target);
        if (!isClickInside) {
            dropdownClose();
        }
    });

    const dropdownButtonsClose = document.querySelectorAll('.mega-dropdown__close');
    dropdownButtonsClose.forEach(dropdownButtonClose => {
        dropdownButtonClose.addEventListener('click', () => {
            dropdownClose();
        });
    });

    headerNavLinks.forEach(headerNavLink => {
        headerNavLink.addEventListener('click', (event) => {
            event.preventDefault();
            const item = event.target;
            const megaDropdownID = item.dataset.target;
            const megaDropdownSelector = `#${megaDropdownID}`;
            const megaDropdown = document.querySelector(megaDropdownSelector);
            dropdownClose()
            item.classList.add('mega-dropdown-open');
            megaDropdown.classList.add('is-open');
            headerNavbar.classList.add('navbar-dark');
        })
    });
} // Megamenu - end

/**
 * Mobile menu
 */
const menuButton = document.getElementById('menuButton');
const offcanvasRight = document.querySelector('#offcanvasRight');
offcanvasRight.addEventListener('show.bs.offcanvas', event => {
    menuButton.classList.add('is-active');
    headerNavbar.classList.add('navbar-dark');
});
offcanvasRight.addEventListener('hide.bs.offcanvas', event => {
    menuButton.classList.remove('is-active');
    if (window.scrollY > 100 || !headerNavbar.parentElement.classList.contains('has-navbar-dark')) {
        headerNavbar.classList.remove('navbar-dark');
    }
});
const offcanvasNavLinks = offcanvasRight.querySelectorAll('.nav-link');
if (offcanvasNavLinks.length) {
    offcanvasNavLinks.forEach(navLink => {
        navLink.addEventListener('click', (e) => {
            if (!navLink.parentNode.classList.contains('dropdown')) {
                return;
            }
            e.preventDefault();
            e.stopPropagation();
            const linkText = e.target.textContent;
            const btnBack = document.createElement('button');
            const dropdownTitle = document.createElement('span');
            const siblingDropdownMenu = e.target.parentNode.querySelector('.dropdown-menu');
            btnBack.setAttribute('class', 'btn btn-back btn-reset subnav-link gray-400');
            btnBack.setAttribute('role', 'button');
            btnBack.innerText = 'Back';
            btnBack.addEventListener('click', () => {
                siblingDropdownMenu.classList.toggle('show');
            });
            dropdownTitle.setAttribute('class', 'dropdown-title subnav-link');
            dropdownTitle.textContent = linkText;
            if (siblingDropdownMenu && siblingDropdownMenu.classList.contains('dropdown-menu')) {
                if (siblingDropdownMenu.querySelector('.dropdown-title')) {
                    siblingDropdownMenu.querySelector('.dropdown-title').remove();
                }
                siblingDropdownMenu.prepend(dropdownTitle);
                if (siblingDropdownMenu.querySelector('.btn-back')) {
                    siblingDropdownMenu.querySelector('.btn-back').remove();
                }
                siblingDropdownMenu.prepend(btnBack);
            }
        });
    });
}

/**
 *  Scroll handling
 */
let lastScrollTop = 0;
const isScrollingDown = () => {
    let goingDown = false;
    let st = window.scrollY || document.documentElement.scrollTop;
    goingDown = st > lastScrollTop;
    lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    return goingDown;
};

const handleScroll = () => {
    if (isScrollingDown()) {
        document.body.classList.remove("scroll-up");
        document.body.classList.add("scroll-down");
    } else {
        document.body.classList.add("scroll-up");
        document.body.classList.remove("scroll-down");
        headerNavbar.classList.remove('navbar-dark');
    }
    if (window.scrollY < 100) {
        document.body.classList.remove("scroll-up");
        if (headerNavbar.parentElement.classList.contains('has-navbar-dark')) {
            headerNavbar.classList.add('navbar-dark');
        }
    }
};
window.addEventListener("scroll", handleScroll);
