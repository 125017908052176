import jQuery from "jquery";
const auth = require('./configurator/auth');
const sketchbook = require('./sketchbook/sketchbook');

if (typeof loadSketchbook !== "undefined" && jQuery('body').hasClass("myfogher-sketchbook")) {
    auth.token(function () {
        auth.getLoggedUser(function (result) {
            if (result.isLoggedIn) {
                sketchbook.load();
            }
        });
    });

    sketchbook.event();
}
