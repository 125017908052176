const jQuery = require("jquery");
module.exports = {
    configuration: null,
    load: function () {
        jQuery.ajax({
            type: 'POST',
            url: loadSketchbook,
            data: {},
            success(result) {
                module.exports.configuration = result;

                jQuery("#kitchen_code").text(result.code_short);
                jQuery("#customer_name").text((result.name)?(result.name + " " + result.surname):result.user.name);
                const data = moment(result.created_at).format("DD/MM/YYYY");
                jQuery("#data").text(data);

                for (var i = 0; i < auth.status.user.roles.length; i++) {
                    //if (auth.status.user.roles[i].name === 'Retailer' || auth.status.user.roles[i].name === 'Admin') {
                    if (jQuery.inArray(auth.status.user.roles[i].name, ['Retailer', 'Admin', 'Agenti Italia', 'Agenti Europa', 'Agenti Mondo']) !== -1) {
                        if (!result.locked) {
                            jQuery("#retailer").show();
                        }
                        jQuery(".customer").hide();
                    }
                }

                //var dati_cucina = JSON.parse(result.unity_config);
                var dati_cucina = result.unity_config;

                if(dati_cucina.output.misure) {
                    jQuery("#kitchen_width").text(dati_cucina.output.misure.larghezza_mm);
                    jQuery("#kitchen_depth").text(dati_cucina.output.misure.profondità_mm);
                } else {
                    jQuery(".container_misure").hide();
                }

                jQuery(".finitura_selezionata").append("<div class='col-12'><div class='config-save__schema'><span class='text--xsmall'>" + dati_cucina.output.finitura + "</span></div></div>");

                const service_keys = ["base", "top_applicato", "barbecue_applicato", "frigo_applicato", "tavolo_applicato", "snack_bar_applicata", "schienale_applicato", "kit_ruote_applicato"];
                var all_requests = [];
                var totalone = 0;
                var conta_el = 0;

                var valuta = " pt ";
                if(jQuery(".lista_componenti").first().hasClass("isRetIta"))
                    valuta = " € ";

                $.each(service_keys, function(key, value) {
                    if(dati_cucina.output[value] != null) {
                        conta_el++;
                        var tipoCorrente = value;

                        //carico i dati del prodotto che mi servono
                        all_requests.push($.ajax({
                            type: 'POST',
                            url: getProductData,
                            indexValue: value,
                            data: {
                                sku: dati_cucina.output[value],
                                conta: conta_el
                            },
                            success(result) {
                                if (result.price) {
                                    var prc_obj = JSON.parse(result.price);
                                    var prezzo_giusto = "";
                                    $.each(prc_obj, function(subkey, subvalue) {
                                        //controllo i ruoli per mostrare solo i listini collegati
                                        if(subvalue.area == "Italia" && !jQuery(".lista_componenti").first().hasClass("isRetIta")) {
                                            return;
                                        }
                                        if(subvalue.area == "Estero" && !jQuery(".lista_componenti").first().hasClass("isRetEu")) {
                                            return;
                                        }
                                        if(subvalue.area == "Mondo" && !jQuery(".lista_componenti").first().hasClass("isRetWor")) {
                                            return;
                                        }

                                        totalone += Number(subvalue.price);
                                        prezzo_giusto = "&nbsp;| " + subvalue.price + valuta;
                                    });
                                }

                                if(result) {
                                    //aggiungo il componente
                                    if(tipoCorrente == "tavolo_applicato") {
                                        //aggiungo altre info
                                        jQuery(".lista_componenti").append("<div class='col-12 pb-2'><div class='config-save__schema'><span class='text--xsmall'>" + result.sku + " - " + result.name + "</span><b>" + prezzo_giusto + "</b><span class='text--xsmall'>&nbsp;(" + dati_cucina.output.tavolo_orientamento + " " + dati_cucina.output.tavolo_struttura + ")</span></div></div>");
                                    } else {
                                        jQuery(".lista_componenti").append("<div class='col-12 pb-2'><div class='config-save__schema'><span class='text--xsmall'>" + result.sku + " - " + result.name + "</span><b>" + prezzo_giusto + "</b></div></div>");
                                    }
                                }
                            }
                        }));
                    }
                });

                const obj_keys = ["sedie_applicate", "porta_spezie_applicati"];
                $.each(obj_keys, function(key, value) {
                    if(typeof dati_cucina.output[value] !== "undefined" && dati_cucina.output[value] != null) {
                        conta_el++;

                        //carico i dati del prodotto che mi servono
                        all_requests.push($.ajax({
                            type: 'POST',
                            url: getProductData,
                            data: {
                                sku: dati_cucina.output[value].code,
                                conta: conta_el
                            },
                            success(result) {
                                if (result.price) {
                                    var prc_obj = JSON.parse(result.price);
                                    var prezzo_giusto = "";
                                    $.each(prc_obj, function(subkey, subvalue) {
                                        //controllo i ruoli per mostrare solo i listini collegati
                                        if(subvalue.area == "Italia" && !jQuery(".lista_componenti").first().hasClass("isRetIta")) {
                                            return;
                                        }
                                        if(subvalue.area == "Estero" && !jQuery(".lista_componenti").first().hasClass("isRetEu")) {
                                            return;
                                        }
                                        if(subvalue.area == "Mondo" && !jQuery(".lista_componenti").first().hasClass("isRetWor")) {
                                            return;
                                        }

                                        totalone += Number(subvalue.price) * dati_cucina.output[value].numero;
                                        prezzo_giusto = "&nbsp;| " + (Number(subvalue.price) * dati_cucina.output[value].numero) + valuta;
                                    });
                                }

                                if(result) {
                                    //aggiungo il componente
                                    jQuery(".lista_componenti").append("<div class='col-12 pb-2'><div class='config-save__schema'><span class='text--xsmall'>" + dati_cucina.output[value].numero + "x " + result.sku + " - " + result.name + "</span><b>" + prezzo_giusto + "</b></div></div>");
                                }
                            }
                        }));
                    }
                });

                //cerco eventuali accessori
                const accessories_keys = ["barbecue_accessori_applicato", "options_applicati"];
                $.each(accessories_keys, function(topkey, topvalue) {
                    if(typeof dati_cucina.output[topvalue] !== "undefined" && dati_cucina.output[topvalue] != null) {
                        $.each(dati_cucina.output[topvalue], function(key, value) {
                            conta_el++;

                            //carico i dati del prodotto che mi servono
                            all_requests.push($.ajax({
                                type: 'POST',
                                url: getProductData,
                                indexValue: value,
                                data: {
                                    sku: value,
                                    conta: conta_el
                                },
                                success(result) {
                                    if (result.price) {
                                        var prc_obj = JSON.parse(result.price);
                                        var prezzo_giusto = "";
                                        $.each(prc_obj, function(subkey, subvalue) {
                                            //controllo i ruoli per mostrare solo i listini collegati
                                            if(subvalue.area == "Italia" && !jQuery(".lista_componenti").first().hasClass("isRetIta")) {
                                                return;
                                            }
                                            if(subvalue.area == "Estero" && !jQuery(".lista_componenti").first().hasClass("isRetEu")) {
                                                return;
                                            }
                                            if(subvalue.area == "Mondo" && !jQuery(".lista_componenti").first().hasClass("isRetWor")) {
                                                return;
                                            }

                                            totalone += Number(subvalue.price);
                                            prezzo_giusto = "&nbsp;| " + subvalue.price + valuta;
                                        });
                                    }

                                    if(result) {
                                        //aggiungo il componente
                                        jQuery(".lista_componenti").append("<div class='col-12 pb-2'><div class='config-save__schema'><span class='text--xsmall'>" + result.sku + " - " + result.name + "</span><b>" + prezzo_giusto + "</b></div></div>");
                                    }
                                }
                            }));
                        });
                    }
                });

                jQuery.when.apply(jQuery, all_requests).done(function () {
                    //solo quando ho concluso tutte le chiamate ho il totale giusto
                    $(".total_tit").append("&nbsp;" + totalone + valuta);
                });

                if (result.locked) {
                    jQuery("#confirmed_order").show();

                    jQuery(".config-summary-values__user__name").text(result.name);
                    jQuery(".config-summary-values__user__surname").text(result.surname);
                    jQuery(".config-summary-values__user__email").text(result.email);
                    jQuery(".config-summary-values__user__address").text(result.address);
                    jQuery(".config-summary-values__user__city").text(result.city);
                    jQuery(".config-summary-values__user__cap").text(result.cap);
                    jQuery(".config-summary-values__user__state").text(result.state);
                    jQuery(".config-summary-values__user__country").text(result.country);
                    jQuery(".config-summary-values__user__phone").text(result.phone);
                    jQuery(".config-summary-values__notes").text(result.notes);
                } else {
                    jQuery("input[name=customer\\[name\\]]").val(result.name);
                    jQuery("input[name=customer\\[surname\\]]").val(result.surname);
                    jQuery("input[name=customer\\[email\\]]").val(result.email);
                    jQuery("input[name=customer\\[address\\]]").val(result.address);
                    jQuery("input[name=customer\\[city\\]]").val(result.city);
                    jQuery("input[name=customer\\[cap\\]]").val(result.cap);
                    jQuery("input[name=customer\\[state\\]]").val(result.state);
                    jQuery("input[name=customer\\[country\\]]").val(result.country);
                    jQuery("input[name=customer\\[phone\\]]").val(result.phone);
                    jQuery("textarea[name=config\\[notes\\]]").val(result.notes);

                    jQuery("#delete_kitchen").attr("data-value", result.id);
                }

                if (window.matchMedia('(min-width: 992px)').matches) {
                    $('.config').append('<div class="fpb"></div>');
                    setTimeout(function (){
                        $("#final_preview").sticky({topSpacing:64});
                    },250);
                }

                module.exports.event();

                jQuery(document).find('[data-bs-toggle="tooltip"]').each(function () {
                    return new bootstrap.Tooltip(this);
                });
            },
            error(jqXHR, result) {
                var message = [];
                for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                    message.push(element.join(", "));
                }
                swal({
                    title: translations.sketchbook,
                    text: message.join("\n"),
                    icon: 'error',
                }).then(function () {
                    window.location.href = goToSketchbook;
                });
            },
            statusCode: {
                419: function() {
                    auth.token(function () {
                        module.exports.load();
                    });
                }
            },
        });
    },
    event: function () {
        window.addEventListener('onLoggedOut', function (e) {
            window.location.href = goToSketchbook;
        });

        jQuery('.config__error-close').on('click',function(event){
            var tl = new gsap.timeline({
                onComplete: function () {
                    $('.send_order__modal').removeClass('prepare');
                }
            });
            tl.to('.send_order__modal',{
                duration: .5,
                opacity: 0
            });
        });

        if (!module.exports.configuration.locked) {
            jQuery("#delete_kitchen").on("click", function () {
                module.exports.removeKitchen(this);
            });

            jQuery("#save_details").on("click", function () {
                module.exports.saveKitchen();
            });

            jQuery("#send_order").on("click", function () {
                module.exports.openOrderModal();
            });

            jQuery("#send_mail_button").on("click", function () {
                module.exports.orderKitchen();
            });

            jQuery("#download_order_button").on("click", function () {
                module.exports.mailToClient();
            });

            jQuery("#copy_code_to_clipboard").on("click", function () {
                module.exports.copyTextToClipboard(jQuery("#kitchen_code").text());
            });

            jQuery(".sketchbook-list__duplicate").click(function () {
                module.exports.duplicate(this);
            });
        }
    },
    removeKitchen: function (element) {
        swal({
            title: translations.attention,
            text: translations.are_you_sure_delete_config,
            icon: 'warning',
            dangerMode: true,
            buttons: {
                cancel: translations.cancel,
                continue: {
                    text: translations.yes_delete,
                    value: "ok",
                },
            },
        }).then(value => {
            if (value) {
                jQuery.ajax({
                    type: 'POST',
                    url: removeFromSketchbook,
                    data: {
                        id: jQuery(element).data("value")
                    },
                    success(result) {
                        if (result.success) {
                            swal({
                                title: translations.remove_kitchen,
                                text: result.message,
                                icon: 'success',
                            }).then(function () {
                                window.location.href = goToSketchbook;
                            });
                        }
                    },
                    error(jqXHR, textStatus) {
                        var message = [];
                        for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                            message.push(element.join(", "));
                        }
                        swal({
                            title: translations.remove_kitchen,
                            text: message.join("\n"),
                            icon: 'error',
                        });
                    }
                });
            } else {

            }
        });
    },
    saveKitchen: function () {
        if (!jQuery('#retailer')[0].checkValidity()) {
            jQuery('#retailer')[0].reportValidity();
            jQuery(window).scroll(function() {
                clearTimeout(jQuery.data(this, 'scrollTimer'));
                jQuery.data(this, 'scrollTimer', setTimeout(function() {
                    jQuery('#retailer')[0].reportValidity();
                    jQuery(window).off('scroll');
                }, 250));
            });
            return false;
        }
        jQuery.ajax({
            type: 'POST',
            url: saveKitchen,
            data: jQuery('#retailer').serialize(),
            success(result) {
                if (result.success) {
                    swal({
                        title: translations.save_kitchen,
                        text: result.message,
                        icon: 'success',
                    });
                }
            },
            error(jqXHR, textStatus) {
                var message = [];
                for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                    message.push(element.join(", "));
                }
                swal({
                    title: translations.save_kitchen,
                    text: message.join("\n"),
                    icon: 'error',
                });
            }
        });
    },
    openOrderModal: function () {
        jQuery.ajax({
            type: 'POST',
            url: saveKitchen,
            data: jQuery('#retailer').serialize(),
            success(result) {
                if (result.success) {
                    jQuery.ajax({
                        type: 'POST',
                        url: sendOrderKitchen,
                        data: jQuery('.send_order__form').serialize(),
                        success(result) {
                            if (result.success) {
                                swal({
                                    title: translations.send_mail,
                                    text: result.message,
                                    icon: 'success',
                                }).then(function () {
                                    window.location.href = goToSketchbook;
                                });
                            }
                        },
                        error(jqXHR, textStatus) {
                            var message = [];
                            for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                                message.push(element.join(", "));
                            }
                            swal({
                                title: translations.send_mail,
                                text: message.join("\n"),
                                icon: 'error',
                            });
                        }
                    });
                }
            },
            error(jqXHR, textStatus) {
                var message = [];
                for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                    message.push(element.join(", "));
                }
                swal({
                    title: translations.save_kitchen,
                    text: message.join("\n"),
                    icon: 'error',
                });
            }
        });
    },
    orderKitchen: function () {
        if (!jQuery(".send_order__form")[0].checkValidity()) {
            jQuery('.send_order__form')[0].reportValidity();
            return false;
        }
        jQuery.ajax({
            type: 'POST',
            url: saveKitchen,
            data: jQuery('#retailer').serialize(),
            success(result) {
                if (result.success) {
                    jQuery.ajax({
                        type: 'POST',
                        url: sendOrderKitchen,
                        data: jQuery('.send_order__form').serialize(),
                        success(result) {
                            if (result.success) {
                                swal({
                                    title: translations.send_mail,
                                    text: result.message,
                                    icon: 'success',
                                }).then(function () {
                                    window.location.href = goToSketchbook;
                                });
                            }
                        },
                        error(jqXHR, textStatus) {
                            var message = [];
                            for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                                message.push(element.join(", "));
                            }
                            swal({
                                title: translations.send_mail,
                                text: message.join("\n"),
                                icon: 'error',
                            });
                        }
                    });
                }
            },
            error(jqXHR, textStatus) {
                var message = [];
                for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                    message.push(element.join(", "));
                }
                swal({
                    title: translations.save_kitchen,
                    text: message.join("\n"),
                    icon: 'error',
                });
            }
        });
    },
    mailToClient: function () {
        jQuery.ajax({
            type: 'POST',
            url: orderKitchenGetData,
            data: {},
            success(result) {
                var url = "mailto:infobike@pinarello.com?subject=New MyFogher Order&body="+encodeURIComponent(result);
                console.log(url);
                window.location.href = url;
            },
            error(jqXHR, textStatus) {
                var message = [];
                for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                    message.push(element.join(", "));
                }
                swal({
                    title: translations.send_mail,
                    text: message.join("\n"),
                    icon: 'error',
                });
            }
        });
    },
    duplicate: function (element) {
        swal({
            title: translations.attention,
            text: translations.are_you_sure_duplicate_config,
            icon: 'warning',
            dangerMode: true,
            buttons: {
                cancel: translations.cancel,
                continue: {
                    text: translations.yes_duplicate,
                    value: "ok",
                },
            },
        }).then(value => {
            if (value) {
                jQuery.ajax({
                    type: 'POST',
                    url: duplicateKitchen,
                    data: {
                        id: jQuery(element).data("value")
                    },
                    success(result) {
                        if (result.success) {
                            swal({
                                title: translations.duplicate_kitchen,
                                text: result.message,
                                icon: 'success',
                            }).then(function () {
                                window.location.href = goToSketchbook;
                            });
                        }
                    },
                    error(jqXHR, textStatus) {
                        var message = [];
                        for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                            message.push(element.join(", "));
                        }
                        swal({
                            title: translations.duplicate_kitchen,
                            text: message.join("\n"),
                            icon: 'error',
                        });
                    }
                });
            } else {

            }
        });
    },
    fallbackCopyTextToClipboard: function (text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand("copy");
            var msg = successful ? "successful" : "unsuccessful";
            console.log("Fallback: Copying text command was " + msg);
        } catch (err) {
            console.error("Fallback: Oops, unable to copy", err);
        }

        document.body.removeChild(textArea);
    },
    copyTextToClipboard: function (text) {
        if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(
            function() {
                swal({
                    title: '',
                    text: translations.text_copied_to_clipboard,
                    icon: 'success',
                });
            },
            function(err) {
                console.error("Async: Could not copy text: ", err);
            }
        );
    }
}

//const jQuery = require('jquery')
const {gsap} = require('../../gsap')
var moment = require('moment')
const auth = require('../configurator/auth');
require('../jquery.sticky');
const bootstrap = require('bootstrap')
