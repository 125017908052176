module.exports = {
    load: function (search) {
        jQuery.ajax({
            type: 'POST',
            url: loadSketchbook,
            data: {
                search: search
            },
            success(result) {
                jQuery("#content-ajax").html(result);

                var customHeight = '240px';
                if (window.matchMedia('(min-width: 992px)').matches) {
                    var customHeight = '90vh';
                }

                /*jQuery('#content-ajax [data-fancybox]').fancybox({
                    iframe : {
                        css : {
                            height : customHeight
                        }
                    }
                });*/

                jQuery("#content-ajax").find('[data-bs-toggle="tooltip"]').each(function () {
                    return new bootstrap.Tooltip(this);
                });
            },
            error(result) {
                swal({
                    title: translations.sketchbook,
                    text: result.responseJSON.message,
                    icon: 'error',
                });
            },
            statusCode: {
                419: function() {
                    auth.token(function () {
                        module.exports.load(search);
                    });
                }
            },
        });
    },
    event: function () {
        window.addEventListener('onLoggedIn', function (e) {
            module.exports.load();
        });

        window.addEventListener('onLoggedOut', function (e) {
            window.location.reload();
        });

        jQuery("#content-ajax").on("click", ".sketchbook-top__add:not(.no_choice)", function () {
            var tl = new gsap.timeline({
                onStart: function () {
                    jQuery('.add_kitchen__modal').addClass('prepare');
                }
            });
            tl.to('.add_kitchen__modal',{
                duration: .5,
                opacity: 1
            });
        });

        jQuery('.config__error-close').on('click',function(event){
            var tl = new gsap.timeline({
                onComplete: function () {
                    $('.add_kitchen__modal').removeClass('prepare');
                }
            });
            tl.to('.add_kitchen__modal',{
                duration: .5,
                opacity: 0
            });
        });

        jQuery(".add_kitchen__button").on("click", function () {
            if (!jQuery('.add_kitchen__form')[0].checkValidity()) {
                jQuery('.add_kitchen__form')[0].reportValidity();
                return false;
            }

            module.exports.addKitchenForm(function () {
                var tl = new gsap.timeline({
                    onStart: function () {
                        jQuery('.add_kitchen__modal').removeClass('prepare');
                    }
                });
                tl.to('.add_kitchen__modal',{
                    duration: .5,
                    opacity: 0
                });
            });
        });

        jQuery("#content-ajax").on("click", ".sketchbook-list__delete", function () {
            module.exports.removeKitchen(this);
        });

        jQuery("#content-ajax").on("click", ".sketchbook-top__search a", function () {
            module.exports.load(jQuery(".sketchbook-top__search input").val());
        });

        jQuery("#content-ajax").on("click", ".sketchbook-list__duplicate", function () {
            module.exports.duplicate(this);
        });
    },
    addKitchenForm: function (callback) {
        jQuery.ajax({
            type: 'POST',
            url: addToSketchbook,
            data: jQuery('.add_kitchen__form').serialize(),
            success(result) {
                if (result.success) {
                    swal({
                        title: translations.add_kitchen,
                        text: result.message,
                        icon: 'success',
                    }).then(function () {
                        module.exports.load();
                        if (typeof callback !== "undefined") {
                            callback();
                        }
                    });
                }
            },
            error(jqXHR, textStatus) {
                var message = [];
                for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                    message.push(element.join(", "));
                }
                swal({
                    title: translations.add_kitchen,
                    text: message.join("\n"),
                    icon: 'error',
                });
            }
        });
    },
    removeKitchen: function (element) {
        swal({
            title: translations.attention,
            text: translations.are_you_sure_delete_config,
            icon: 'warning',
            dangerMode: true,
            buttons: {
                cancel: translations.cancel,
                continue: {
                    text: translations.yes_delete,
                    value: "ok",
                },
            },
        }).then(value => {
            if (value) {
                jQuery.ajax({
                    type: 'POST',
                    url: removeFromSketchbook,
                    data: {
                        id: jQuery(element).data("value")
                    },
                    success(result) {
                        if (result.success) {
                            swal({
                                title: translations.remove_kitchen,
                                text: result.message,
                                icon: 'success',
                            }).then(function () {
                                module.exports.load();
                            });
                        }
                    },
                    error(jqXHR, textStatus) {
                        var message = [];
                        for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                            message.push(element.join(", "));
                        }
                        swal({
                            title: translations.remove_kitchen,
                            text: message.join("\n"),
                            icon: 'error',
                        });
                    }
                });
            } else {

            }
        });
    },
    duplicate: function (element) {
        swal({
            title: translations.attention,
            text: translations.are_you_sure_duplicate_config,
            icon: 'warning',
            dangerMode: true,
            buttons: {
                cancel: translations.cancel,
                continue: {
                    text: translations.yes_duplicate,
                    value: "ok",
                },
            },
        }).then(value => {
            if (value) {
                jQuery.ajax({
                    type: 'POST',
                    url: duplicateKitchen,
                    data: {
                        id: jQuery(element).data("value")
                    },
                    success(result) {
                        if (result.success) {
                            swal({
                                title: translations.duplicate_kitchen,
                                text: result.message,
                                icon: 'success',
                            }).then(function () {
                                module.exports.load();
                            });
                        }
                    },
                    error(jqXHR, textStatus) {
                        var message = [];
                        for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                            message.push(element.join(", "));
                        }
                        swal({
                            title: translations.duplicate_kitchen,
                            text: message.join("\n"),
                            icon: 'error',
                        });
                    }
                });
            } else {

            }
        });
    },
}

const jQuery = require('jquery')
const {gsap} = require('../../gsap')
const auth = require('../configurator/auth')
const bootstrap = require('bootstrap')
