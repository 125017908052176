import {gsap} from "./gsap";
import jQuery from "jquery";
import alert from "bootstrap/js/src/alert";


if (document.body.classList.contains('product-list')) {
    jQuery(document).ready(function($) {
        $('body').on('click', '.form-check-input', function (event) {
            /*var filter = $(this).attr("name");
            var values = [];
            $("input[name='" + filter + "']:checked").each(function () {
                values.push($(this).val());
            });

            console.log(values);*/
            //$("#filtro_lista").submit();

            $(".filter-res-container").load(window.loadFilterUrl, $(this).closest(".filtro_lista").serializeArray());
            $(".btn-close").click();
        });

        $('body').on('click', '.filter_remover', function (event) {
            $('.form-check-input[value=' + $(this).attr("rel") + ']').prop( "checked", false );
            $(".filter-res-container").load(window.loadFilterUrl, $(".form_filtro_mobile").serializeArray());
        });
    });
}
