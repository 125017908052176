import jQuery from "jquery";
const auth = require('./configurator/auth');
var sketchbook = "";
if(jQuery(".lista_componenti").first().hasClass("isAgher")) {
    sketchbook = require('./sketchbook/kitchen-detail-agher');
} else {
    sketchbook = require('./sketchbook/kitchen-detail');
}

if (typeof token !== "undefined" && jQuery('body').hasClass("kitchen-summary")) {
    auth.token(function () {
        auth.getLoggedUser(function (result) {
            if (result.isLoggedIn) {
                sketchbook.load();
            } else {
                window.location.href = goToSketchbook;
            }
        }, function () {
            window.location.href = goToSketchbook;
        });
    });
}
