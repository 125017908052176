import jQuery from "jquery";
const auth = require('./myfogher/configurator/auth');
//import auth from "./myfogher/configurator/auth";

if (typeof token !== "undefined" && jQuery('body').hasClass("find-a-store")) {
    auth.token(function () {
        auth.getLoggedUser(function (result) {
            if (result.isLoggedIn) {
                //sketchbook.load();
            } else {
                window.location.href = goToSketchbook;
            }
        }, function () {
            window.location.href = goToSketchbook;
        });
    });
}

jQuery("#ordina").on("click", function () {
    jQuery.ajax({
        type: 'POST',
        url: order,
        data: {store_mail: selected_store.data.email, store_name: selected_store.name},
        success(result) {
            window.location.href = goToKitchen;
            /*swal({
                title: translations.profile,
                text: result.message,
                icon: 'success',
            }).then(function () {
                window.location.href = goToKitchen;
            });*/
        },
        error(result) {
            swal({
                title: translations.profile,
                text: result.responseJSON.message,
                icon: 'error',
            });
        }
    });
});
