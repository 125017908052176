const jQuery = require("jquery");
module.exports = {
    configuration: null,
    load: function () {
        jQuery.ajax({
            type: 'POST',
            url: loadSketchbook,
            data: {},
            success(result) {
                module.exports.configuration = result;

                jQuery("#kitchen_code").text(result.code_short);
                jQuery("#customer_name").text((result.name)?(result.name + " " + result.surname):result.user.name);
                const data = moment(result.created_at).format("DD/MM/YYYY");
                jQuery("#data").text(data);

                for (var i = 0; i < auth.status.user.roles.length; i++) {
                    //if (auth.status.user.roles[i].name === 'Retailer' || auth.status.user.roles[i].name === 'Admin') {
                    if (jQuery.inArray(auth.status.user.roles[i].name, ['Retailer', 'Admin', 'Agenti Italia', 'Agenti Europa', 'Agenti Mondo']) !== -1) {
                        if (!result.locked) {
                            jQuery("#retailer").show();
                        }
                        jQuery(".customer").hide();
                    }
                }

                //var dati_cucina = JSON.parse(result.unity_config);
                var dati_cucina = result.unity_config;

                if(dati_cucina.misure) {
                    jQuery("#kitchen_width").text(dati_cucina.misure.larghezza_mm);
                    jQuery("#kitchen_depth").text(dati_cucina.misure.profondità_mm);
                } else {
                    jQuery(".container_misure").hide();
                }

                jQuery(".finitura_selezionata").append("<div class='col-12'><div class='config-save__schema'><span class='text--xsmall'>" + dati_cucina["finitura"] + "</span></div></div>");

                const service_keys = ["kit_giunzione_totali", "modulo_supporto_85_totali", "appendiera_applicata", "appenderia_applicata", "kit_giunzione_angolare_applicato", "finitura", "codice_finitura", "misure"];
                var has_carenature = false;
                var has_options = false;
                var has_supporti = false;
                var all_requests = [];
                var totalone = 0;
                var conta_el = 0;

                var valuta = " pt ";
                if(jQuery(".lista_componenti").first().hasClass("isRetIta"))
                    valuta = " € ";

                $.each(dati_cucina, function(key, value) {
                    if(!service_keys.includes(key)) {// && typeof value.descrizione !== 'undefined'
                        conta_el++;

                        //aggiungo il componente con un b vuoto per contenere i prezzi
                        jQuery(".lista_componenti").append("<div class='col-12 pb-2'><div class='config-save__schema'><span class='text--xsmall'>" + value.codice + " - " + value.descrizione[cur_lang] + "</span><b class='prc-" + value.codice.replace(".", "-") + "_" + conta_el + "'></b></div></div>");

                        //carico i dati del prodotto che mi servono
                        all_requests.push($.ajax({
                            type: 'POST',
                            url: getProductData,
                            data: {
                                sku: value.codice + "-" + dati_cucina.codice_finitura,
                                conta: conta_el
                            },
                            success(result) {
                                if (result.price) {
                                    var prc_obj = JSON.parse(result.price);
                                    $.each(prc_obj, function(subkey, subvalue) {
                                        //controllo i ruoli per mostrare solo i listini collegati
                                        if(subvalue.area == "Italia" && !jQuery(".lista_componenti").first().hasClass("isRetIta")) {
                                            return;
                                        }
                                        if(subvalue.area == "Estero" && !jQuery(".lista_componenti").first().hasClass("isRetEu")) {
                                            return;
                                        }
                                        if(subvalue.area == "Mondo" && !jQuery(".lista_componenti").first().hasClass("isRetWor")) {
                                            return;
                                        }

                                        totalone += Number(subvalue.price);

                                        $(".prc-" + value.codice.replace(".", "-") + "_" + result.conta_el).append("&nbsp;| " + subvalue.price + valuta);
                                    });
                                }
                            }
                        }));

                        //se ne ha, aggiungo carenature alla lista
                        if(value.lk_kit_carenatura_applicata.length) {
                            has_carenature = true;
                            $.each(value.lk_kit_carenatura_applicata, function(carkey, carvalue) {
                                //aggiungo la carenatura con un b vuoto per contenere i prezzi
                                jQuery(".carena_tit").append("<div class='col-12 pb-2'><div class='config-save__schema'><span class='text--xsmall'>" + carvalue + "</span><b class='prc-" + carvalue.replace(".", "-") + "_" + conta_el + "'></b></div></div>");

                                //carico i dati del prodotto che mi servono
                                all_requests.push($.ajax({
                                    type: 'POST',
                                    url: getProductData,
                                    data: {
                                        sku: carvalue + "-" + dati_cucina.codice_finitura,
                                        conta: conta_el
                                    },
                                    success(result) {
                                        if (result.price) {
                                            var prc_obj = JSON.parse(result.price);
                                            var add_prices = "";
                                            if(result) {
                                                add_prices = "&nbsp;<span class='not_really_bold'>- " + result.name + "</span>";
                                            }

                                            $.each(prc_obj, function (lowkey, lowvalue) {
                                                //controllo i ruoli per mostrare solo i listini collegati
                                                if(lowvalue.area == "Italia" && !jQuery(".lista_componenti").first().hasClass("isRetIta")) {
                                                    return;
                                                }
                                                if(lowvalue.area == "Estero" && !jQuery(".lista_componenti").first().hasClass("isRetEu")) {
                                                    return;
                                                }
                                                if(lowvalue.area == "Mondo" && !jQuery(".lista_componenti").first().hasClass("isRetWor")) {
                                                    return;
                                                }

                                                totalone += Number(lowvalue.price);

                                                add_prices += "&nbsp;| " + lowvalue.price + valuta;
                                            });

                                            if ($(".prc-" + carvalue.replace(".", "-") + "_" + result.conta_el).empty()) {
                                                $(".prc-" + carvalue.replace(".", "-") + "_" + result.conta_el).append(add_prices);
                                            }
                                        }
                                    }
                                }));
                            });
                        }
                        //se ne ha, aggiungo opzioni alla lista
                        var all_options = [...value.options_applicati, ...value.ripiani_opzionali_applicati, value.snakBar_applicato];
                        if(all_options.length) {
                            has_options = true;
                            $.each(all_options, function(optkey, optvalue) {
                                if(optvalue == null) {
                                    return;
                                }console.log(value.options_applicati_n_input[optvalue]);
                                //aggiungo l'opzione con un b vuoto per contenere i prezzi, eventualmente con quantità
                                if(typeof value.options_applicati_n_input[optvalue] !== 'undefined') {
                                    jQuery(".options_tit").append("<div class='col-12 pb-2'><div class='config-save__schema'><span class='text--xsmall'>" + value.options_applicati_n_input[optvalue] + "x " + optvalue + "</span><b class='prc-" + optvalue.replace(".", "-") + "_" + conta_el + "'></b></div></div>");
                                } else {
                                    jQuery(".options_tit").append("<div class='col-12 pb-2'><div class='config-save__schema'><span class='text--xsmall'>" + optvalue + "</span><b class='prc-" + optvalue.replace(".", "-") + "_" + conta_el + "'></b></div></div>");
                                }

                                //carico i dati del prodotto che mi servono
                                all_requests.push($.ajax({
                                    type: 'POST',
                                    url: getProductData,
                                    data: {
                                        sku: optvalue + "-" + dati_cucina.codice_finitura,
                                        conta: conta_el
                                    },
                                    success(result) {
                                        var add_prices = "";
                                        if(result) {
                                            add_prices = "&nbsp;<span class='not_really_bold'>- " + result.name + "</span>";
                                        }

                                        if (result.price) {
                                            var prc_obj = JSON.parse(result.price);
                                            $.each(prc_obj, function (midkey, midvalue) {
                                                //controllo i ruoli per mostrare solo i listini collegati
                                                if(midvalue.area == "Italia" && !jQuery(".lista_componenti").first().hasClass("isRetIta")) {
                                                    return;
                                                }
                                                if(midvalue.area == "Estero" && !jQuery(".lista_componenti").first().hasClass("isRetEu")) {
                                                    return;
                                                }
                                                if(midvalue.area == "Mondo" && !jQuery(".lista_componenti").first().hasClass("isRetWor")) {
                                                    return;
                                                }

                                                if(typeof value.options_applicati_n_input[optvalue] !== 'undefined') {
                                                    totalone += Number(midvalue.price) * value.options_applicati_n_input[optvalue];
                                                    add_prices += " | " + (Number(midvalue.price) * value.options_applicati_n_input[optvalue]) + valuta;
                                                } else {
                                                    totalone += Number(midvalue.price);
                                                    add_prices += " | " + midvalue.price + valuta;
                                                }
                                            });
                                        }

                                        if ($(".prc-" + optvalue.replace(".", "-") + "_" + result.conta_el).empty()) {
                                            $(".prc-" + optvalue.replace(".", "-") + "_" + result.conta_el).append(add_prices);
                                        }
                                    }
                                }));
                            });
                        }
                        //se ne ha, aggiungo moduli supporto alla lista
                        if(typeof value.codice_modulo_supporto !== 'undefined') {
                            has_supporti = true;
                            //aggiungo il modulo supporto con un b vuoto per contenere i prezzi
                            jQuery(".suppor_tit").append("<div class='col-12 pb-2'><div class='config-save__schema'><span class='text--xsmall'>" + value.codice_modulo_supporto + "</span><b class='prc-" + value.codice_modulo_supporto.replace(".", "-") + "_" + conta_el + "'></b></div></div>");

                            //carico i dati del prodotto che mi servono
                            all_requests.push($.ajax({
                                type: 'POST',
                                url: getProductData,
                                data: {
                                    sku: value.codice_modulo_supporto + "-" + dati_cucina.codice_finitura,
                                    conta: conta_el
                                },
                                success(result) {
                                    var add_prices = "";
                                    if(result) {
                                        add_prices = "&nbsp;<span class='not_really_bold'>- " + result.name + "</span>";
                                    }

                                    if (result.price) {
                                        var prc_obj = JSON.parse(result.price);
                                        $.each(prc_obj, function(botkey, botvalue) {
                                            //controllo i ruoli per mostrare solo i listini collegati
                                            if(botvalue.area == "Italia" && !jQuery(".lista_componenti").first().hasClass("isRetIta")) {
                                                return;
                                            }
                                            if(botvalue.area == "Estero" && !jQuery(".lista_componenti").first().hasClass("isRetEu")) {
                                                return;
                                            }
                                            if(botvalue.area == "Mondo" && !jQuery(".lista_componenti").first().hasClass("isRetWor")) {
                                                return;
                                            }

                                            totalone += Number(botvalue.price);

                                            add_prices += " | " + botvalue.price + valuta;
                                        });
                                    }

                                    $(".prc-" + value.codice_modulo_supporto.replace(".", "-") + "_" + result.conta_el).append(add_prices);
                                }
                            }));
                        }
                    }
                });

                if(has_carenature) {
                    jQuery(".carena_tit").removeClass("d-none").appendTo(".lista_componenti");
                }
                if(has_options) {
                    jQuery(".options_tit").removeClass("d-none").appendTo(".lista_componenti");
                }
                if(has_supporti) {
                    jQuery(".suppor_tit").removeClass("d-none").appendTo(".lista_componenti");
                }

                if(dati_cucina["kit_giunzione_totali"] > 0) {
                    jQuery(".value_to_fill").html(dati_cucina["kit_giunzione_totali"]);
                    jQuery(".junkit_tit").removeClass("d-none").appendTo(".lista_componenti");

                    //carico i dati del prodotto che mi servono
                    all_requests.push($.ajax({
                        type: 'POST',
                        url: getProductData,
                        data: {
                            sku: "1063932.0",
                            conta: conta_el
                        },
                        success(result) {
                            if (result.price) {
                                var prc_obj = JSON.parse(result.price);
                                $.each(prc_obj, function(giukey, giuvalue) {
                                    //controllo i ruoli per mostrare solo i listini collegati
                                    if(giuvalue.area == "Italia" && !jQuery(".lista_componenti").first().hasClass("isRetIta")) {
                                        return;
                                    }
                                    if(giuvalue.area == "Estero" && !jQuery(".lista_componenti").first().hasClass("isRetEu")) {
                                        return;
                                    }
                                    if(giuvalue.area == "Mondo" && !jQuery(".lista_componenti").first().hasClass("isRetWor")) {
                                        return;
                                    }

                                    totalone += Number((giuvalue.price * Number(dati_cucina["kit_giunzione_totali"])));

                                    $(".prc-giunzione").append("&nbsp;| " + (giuvalue.price * Number(dati_cucina["kit_giunzione_totali"])) + valuta);
                                });
                            }
                        }
                    }));
                }

                if(dati_cucina["kit_giunzione_angolare_applicato"] == 1) {
                    jQuery(".angkit_tit").removeClass("d-none").appendTo(".lista_componenti");

                    //carico i dati del prodotto che mi servono
                    all_requests.push($.ajax({
                        type: 'POST',
                        url: getProductData,
                        data: {
                            sku: "1064484.0",
                            conta: conta_el
                        },
                        success(result) {
                            if (result.price) {
                                var prc_obj = JSON.parse(result.price);
                                $.each(prc_obj, function(angkey, angvalue) {
                                    //controllo i ruoli per mostrare solo i listini collegati
                                    if(angvalue.area == "Italia" && !jQuery(".lista_componenti").first().hasClass("isRetIta")) {
                                        return;
                                    }
                                    if(angvalue.area == "Estero" && !jQuery(".lista_componenti").first().hasClass("isRetEu")) {
                                        return;
                                    }
                                    if(angvalue.area == "Mondo" && !jQuery(".lista_componenti").first().hasClass("isRetWor")) {
                                        return;
                                    }

                                    totalone += Number(angvalue.price);

                                    $(".prc-angolare").append("&nbsp;| " + angvalue.price + valuta);
                                });
                            }
                        }
                    }));
                }

                if(dati_cucina["modulo_supporto_85_totali"] > 0) {
                    jQuery(".value_to_fill85").html(dati_cucina["modulo_supporto_85_totali"]);
                    jQuery(".giun85_tit").removeClass("d-none").appendTo(".lista_componenti");

                    //carico i dati del prodotto che mi servono
                    all_requests.push($.ajax({
                        type: 'POST',
                        url: getProductData,
                        data: {
                            sku: "1068488.0-" + dati_cucina.codice_finitura,
                            conta: conta_el
                        },
                        success(result) {
                            if (result.price) {
                                var prc_obj = JSON.parse(result.price);
                                $.each(prc_obj, function(giu85key, giu85value) {
                                    //controllo i ruoli per mostrare solo i listini collegati
                                    if(giu85value.area == "Italia" && !jQuery(".lista_componenti").first().hasClass("isRetIta")) {
                                        return;
                                    }
                                    if(giu85value.area == "Estero" && !jQuery(".lista_componenti").first().hasClass("isRetEu")) {
                                        return;
                                    }
                                    if(giu85value.area == "Mondo" && !jQuery(".lista_componenti").first().hasClass("isRetWor")) {
                                        return;
                                    }

                                    totalone += Number((giu85value.price * Number(dati_cucina["modulo_supporto_85_totali"])));

                                    $(".prc-giun85").append("&nbsp;| " + (giu85value.price * Number(dati_cucina["modulo_supporto_85_totali"])) + valuta);
                                });
                            }
                        }
                    }));
                }

                if(dati_cucina["appenderia_applicata"]) {
                    jQuery(".append_tit").removeClass("d-none").appendTo(".lista_componenti");
                    $("#appende_code").html(dati_cucina["appenderia_applicata"]);

                    //carico i dati del prodotto che mi servono
                    all_requests.push($.ajax({
                        type: 'POST',
                        url: getProductData,
                        data: {
                            sku: dati_cucina["appenderia_applicata"],
                            conta: conta_el
                        },
                        success(result) {
                            if (result.price) {
                                var prc_obj = JSON.parse(result.price);
                                $.each(prc_obj, function(appkey, appvalue) {
                                    //controllo i ruoli per mostrare solo i listini collegati
                                    if(appvalue.area == "Italia" && !jQuery(".lista_componenti").first().hasClass("isRetIta")) {
                                        return;
                                    }
                                    if(appvalue.area == "Estero" && !jQuery(".lista_componenti").first().hasClass("isRetEu")) {
                                        return;
                                    }
                                    if(appvalue.area == "Mondo" && !jQuery(".lista_componenti").first().hasClass("isRetWor")) {
                                        return;
                                    }

                                    totalone += Number(appvalue.price);

                                    $(".prc-appenderia").append("&nbsp;| " + appvalue.price + valuta);
                                });
                            }
                        }
                    }));
                }

                jQuery.when.apply(jQuery, all_requests).done(function () {
                    //solo quando ho concluso tutte le chiamate ho il totale giusto
                    $(".total_tit").append("&nbsp;" + totalone + valuta);
                });

                if (result.locked) {
                    jQuery("#confirmed_order").show();

                    jQuery(".config-summary-values__user__name").text(result.name);
                    jQuery(".config-summary-values__user__surname").text(result.surname);
                    jQuery(".config-summary-values__user__email").text(result.email);
                    jQuery(".config-summary-values__user__address").text(result.address);
                    jQuery(".config-summary-values__user__city").text(result.city);
                    jQuery(".config-summary-values__user__cap").text(result.cap);
                    jQuery(".config-summary-values__user__state").text(result.state);
                    jQuery(".config-summary-values__user__country").text(result.country);
                    jQuery(".config-summary-values__user__phone").text(result.phone);
                    jQuery(".config-summary-values__notes").text(result.notes);
                } else {
                    jQuery("input[name=customer\\[name\\]]").val(result.name);
                    jQuery("input[name=customer\\[surname\\]]").val(result.surname);
                    jQuery("input[name=customer\\[email\\]]").val(result.email);
                    jQuery("input[name=customer\\[address\\]]").val(result.address);
                    jQuery("input[name=customer\\[city\\]]").val(result.city);
                    jQuery("input[name=customer\\[cap\\]]").val(result.cap);
                    jQuery("input[name=customer\\[state\\]]").val(result.state);
                    jQuery("input[name=customer\\[country\\]]").val(result.country);
                    jQuery("input[name=customer\\[phone\\]]").val(result.phone);
                    jQuery("textarea[name=config\\[notes\\]]").val(result.notes);

                    jQuery("#delete_kitchen").attr("data-value", result.id);
                }

                if (window.matchMedia('(min-width: 992px)').matches) {
                    $('.config').append('<div class="fpb"></div>');
                    setTimeout(function (){
                        $("#final_preview").sticky({topSpacing:64});
                    },250);
                }

                module.exports.event();

                jQuery(document).find('[data-bs-toggle="tooltip"]').each(function () {
                    return new bootstrap.Tooltip(this);
                });
            },
            error(jqXHR, result) {
                var message = [];
                for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                    message.push(element.join(", "));
                }
                swal({
                    title: translations.sketchbook,
                    text: message.join("\n"),
                    icon: 'error',
                }).then(function () {
                    window.location.href = goToSketchbook;
                });
            },
            statusCode: {
                419: function() {
                    auth.token(function () {
                        module.exports.load();
                    });
                }
            },
        });
    },
    event: function () {
        window.addEventListener('onLoggedOut', function (e) {
            window.location.href = goToSketchbook;
        });

        jQuery('.config__error-close').on('click',function(event){
            var tl = new gsap.timeline({
                onComplete: function () {
                    $('.send_order__modal').removeClass('prepare');
                }
            });
            tl.to('.send_order__modal',{
                duration: .5,
                opacity: 0
            });
        });

        if (!module.exports.configuration.locked) {
            jQuery("#delete_kitchen").on("click", function () {
                module.exports.removeKitchen(this);
            });

            jQuery("#save_details").on("click", function () {
                module.exports.saveKitchen();
            });

            jQuery("#send_order").on("click", function () {
                module.exports.openOrderModal();
            });

            jQuery("#send_mail_button").on("click", function () {
                module.exports.orderKitchen();
            });

            jQuery("#download_order_button").on("click", function () {
                module.exports.mailToClient();
            });

            jQuery("#copy_code_to_clipboard").on("click", function () {
                module.exports.copyTextToClipboard(jQuery("#kitchen_code").text());
            });

            jQuery(".sketchbook-list__duplicate").click(function () {
                module.exports.duplicate(this);
            });
        }
    },
    removeKitchen: function (element) {
        swal({
            title: translations.attention,
            text: translations.are_you_sure_delete_config,
            icon: 'warning',
            dangerMode: true,
            buttons: {
                cancel: translations.cancel,
                continue: {
                    text: translations.yes_delete,
                    value: "ok",
                },
            },
        }).then(value => {
            if (value) {
                jQuery.ajax({
                    type: 'POST',
                    url: removeFromSketchbook,
                    data: {
                        id: jQuery(element).data("value")
                    },
                    success(result) {
                        if (result.success) {
                            swal({
                                title: translations.remove_kitchen,
                                text: result.message,
                                icon: 'success',
                            }).then(function () {
                                window.location.href = goToSketchbook;
                            });
                        }
                    },
                    error(jqXHR, textStatus) {
                        var message = [];
                        for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                            message.push(element.join(", "));
                        }
                        swal({
                            title: translations.remove_kitchen,
                            text: message.join("\n"),
                            icon: 'error',
                        });
                    }
                });
            } else {

            }
        });
    },
    saveKitchen: function () {
        if (!jQuery('#retailer')[0].checkValidity()) {
            jQuery('#retailer')[0].reportValidity();
            jQuery(window).scroll(function() {
                clearTimeout(jQuery.data(this, 'scrollTimer'));
                jQuery.data(this, 'scrollTimer', setTimeout(function() {
                    jQuery('#retailer')[0].reportValidity();
                    jQuery(window).off('scroll');
                }, 250));
            });
            return false;
        }
        jQuery.ajax({
            type: 'POST',
            url: saveKitchen,
            data: jQuery('#retailer').serialize(),
            success(result) {
                if (result.success) {
                    swal({
                        title: translations.save_kitchen,
                        text: result.message,
                        icon: 'success',
                    });
                }
            },
            error(jqXHR, textStatus) {
                var message = [];
                for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                    message.push(element.join(", "));
                }
                swal({
                    title: translations.save_kitchen,
                    text: message.join("\n"),
                    icon: 'error',
                });
            }
        });
    },
    openOrderModal: function () {
        jQuery.ajax({
            type: 'POST',
            url: saveKitchen,
            data: jQuery('#retailer').serialize(),
            success(result) {
                if (result.success) {
                    jQuery.ajax({
                        type: 'POST',
                        url: sendOrderKitchen,
                        data: jQuery('.send_order__form').serialize(),
                        success(result) {
                            if (result.success) {
                                swal({
                                    title: translations.send_mail,
                                    text: result.message,
                                    icon: 'success',
                                }).then(function () {
                                    window.location.href = goToSketchbook;
                                });
                            }
                        },
                        error(jqXHR, textStatus) {
                            var message = [];
                            for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                                message.push(element.join(", "));
                            }
                            swal({
                                title: translations.send_mail,
                                text: message.join("\n"),
                                icon: 'error',
                            });
                        }
                    });
                }
            },
            error(jqXHR, textStatus) {
                var message = [];
                for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                    message.push(element.join(", "));
                }
                swal({
                    title: translations.save_kitchen,
                    text: message.join("\n"),
                    icon: 'error',
                });
            }
        });
    },
    orderKitchen: function () {
        if (!jQuery(".send_order__form")[0].checkValidity()) {
            jQuery('.send_order__form')[0].reportValidity();
            return false;
        }
        jQuery.ajax({
            type: 'POST',
            url: saveKitchen,
            data: jQuery('#retailer').serialize(),
            success(result) {
                if (result.success) {
                    jQuery.ajax({
                        type: 'POST',
                        url: sendOrderKitchen,
                        data: jQuery('.send_order__form').serialize(),
                        success(result) {
                            if (result.success) {
                                swal({
                                    title: translations.send_mail,
                                    text: result.message,
                                    icon: 'success',
                                }).then(function () {
                                    window.location.href = goToSketchbook;
                                });
                            }
                        },
                        error(jqXHR, textStatus) {
                            var message = [];
                            for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                                message.push(element.join(", "));
                            }
                            swal({
                                title: translations.send_mail,
                                text: message.join("\n"),
                                icon: 'error',
                            });
                        }
                    });
                }
            },
            error(jqXHR, textStatus) {
                var message = [];
                for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                    message.push(element.join(", "));
                }
                swal({
                    title: translations.save_kitchen,
                    text: message.join("\n"),
                    icon: 'error',
                });
            }
        });
    },
    mailToClient: function () {
        jQuery.ajax({
            type: 'POST',
            url: orderKitchenGetData,
            data: {},
            success(result) {
                var url = "mailto:infobike@pinarello.com?subject=New MyFogher Order&body="+encodeURIComponent(result);
                console.log(url);
                window.location.href = url;
            },
            error(jqXHR, textStatus) {
                var message = [];
                for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                    message.push(element.join(", "));
                }
                swal({
                    title: translations.send_mail,
                    text: message.join("\n"),
                    icon: 'error',
                });
            }
        });
    },
    duplicate: function (element) {
        swal({
            title: translations.attention,
            text: translations.are_you_sure_duplicate_config,
            icon: 'warning',
            dangerMode: true,
            buttons: {
                cancel: translations.cancel,
                continue: {
                    text: translations.yes_duplicate,
                    value: "ok",
                },
            },
        }).then(value => {
            if (value) {
                jQuery.ajax({
                    type: 'POST',
                    url: duplicateKitchen,
                    data: {
                        id: jQuery(element).data("value")
                    },
                    success(result) {
                        if (result.success) {
                            swal({
                                title: translations.duplicate_kitchen,
                                text: result.message,
                                icon: 'success',
                            }).then(function () {
                                window.location.href = goToSketchbook;
                            });
                        }
                    },
                    error(jqXHR, textStatus) {
                        var message = [];
                        for (const [index, element] of Object.entries(jqXHR.responseJSON.errors)) {
                            message.push(element.join(", "));
                        }
                        swal({
                            title: translations.duplicate_kitchen,
                            text: message.join("\n"),
                            icon: 'error',
                        });
                    }
                });
            } else {

            }
        });
    },
    fallbackCopyTextToClipboard: function (text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand("copy");
            var msg = successful ? "successful" : "unsuccessful";
            console.log("Fallback: Copying text command was " + msg);
        } catch (err) {
            console.error("Fallback: Oops, unable to copy", err);
        }

        document.body.removeChild(textArea);
    },
    copyTextToClipboard: function (text) {
        if (!navigator.clipboard) {
            this.fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(
            function() {
                swal({
                    title: '',
                    text: translations.text_copied_to_clipboard,
                    icon: 'success',
                });
            },
            function(err) {
                console.error("Async: Could not copy text: ", err);
            }
        );
    }
}

//const jQuery = require('jquery')
const {gsap} = require('../../gsap')
var moment = require('moment')
const auth = require('../configurator/auth');
require('../jquery.sticky');
const bootstrap = require('bootstrap')
