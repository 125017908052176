import jQuery from "jquery";
const auth = require('./myfogher/configurator/auth');
//const sketchbook = require('./sketchbook/sketchbook');

if (jQuery('body').hasClass("downloads")) {console.log("ciao");
    auth.token(function () {
        auth.getLoggedUser(function (result) {
            if (result.isLoggedIn) {
                //sketchbook.load();
            }
        });
    });

    window.addEventListener("onLoggedIn", function () {
        location.reload();
    });
    window.addEventListener("onLoggedOut", function () {
        location.reload();
    });

    //sketchbook.event();
}
