import {gsap} from "../gsap";
import {ScrollToPlugin} from "../gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
import jQuery from "jquery";
import moment from 'moment'
import sketchbook from "./sketchbook/sketchbook";
const auth = require('./configurator/auth');
//const configurator = require('./configurator/configurator')
const swal = require('sweetalert');

const urlParams = new URLSearchParams(location.search);
if (urlParams.has('email_verified') && urlParams.get('email_verified') === '1') {
    swal({
        title: translations.email_verified,
        text: translations.email_verified_info,
        icon: 'success',
    });
}

if (typeof token !== 'undefined' && $('body').hasClass("myfogher-configurator")) {
    auth.token();
    auth.getLoggedUser();
}


if (jQuery('body').hasClass("save-myfogher")) {
    window.addEventListener('loggedNowSave', function (e) {
        $.ajax({
            url: linkConfig,
            method: "POST",
            data: {id: savingConfig},
            success: function (result) {
                jQuery("#nonHaSalvato").addClass("d-none");
                jQuery("#haSalvato").removeClass("d-none");
            },
            error: function () {
            }
        });
    });

    sketchbook.event();
}



window.addEventListener('tokenReceived', function (e) {

    auth.loginForm();
    auth.login();
    auth.recoverPassword();
    auth.resetValidation();
    auth.customerRegister();
    auth.retailerRegister();

    jQuery('a.config__bc-sub').click(function () {
        if (!jQuery('input[name=kitchen-code]').val()) {
            return false;
        }

        //configurator.loadConfigurationFromCode(jQuery('input[name=kitchen-code]').val());
    });

    jQuery("input[name=kitchen-code]").on("keydown",function search(e) {
        if(e.keyCode == 13) {
            //configurator.loadConfigurationFromCode(jQuery('input[name=kitchen-code]').val());
        }
    });

    jQuery('.config__error-close').on('click',function(event){
        var tl = new gsap.timeline({
            onComplete: function () {
                $('.login__error').removeClass('prepare');
            }
        });
        tl.to('.login__error',{
            duration: .5,
            opacity: 0
        })
            .add(function () {
                //reset forms
                $('.config__register-form-tab').removeClass('active');
                $('.config__register-form-customerBTN').addClass('active')
                $('.config__register-form-shop').fadeOut(function () {
                    $('.config__register-form-customer').fadeIn();
                });

                $('.config__register-form').fadeOut();
                $('.config__register-form-psw').fadeOut();
                $('.config__login-form').fadeIn();
            });
    });

    $('.config__password-forgot').click(function () {
        $('.config__login-form').fadeOut(function () {
            $('.config__register-form-psw').fadeIn();
        });
    });

    $('.config__register-form-psw-back').click(function () {
        $('.config__register-form-psw').fadeOut(function () {
            $('.config__login-form').fadeIn();
        });
    });

    $('.config__register-form-back').click(function () {
        $('.config__register-form').fadeOut(function () {
            $('.config__login-form').fadeIn();
        });
    });

    $('.config__user-create').click(function () {
        $('.config__login-form').fadeOut(function () {
            $('.config__register-form').fadeIn();
        });
    });

    $('.config__register-form-shopBTN').click(function () {
        $('.config__register-form-tab').removeClass('active');
        $(this).addClass('active')
        $('.config__register-form-customer').fadeOut(function () {
            $('.config__register-form-shop').fadeIn();
        });
    })

    $('.config__register-form-customerBTN').click(function () {
        $('.config__register-form-tab').removeClass('active');
        $(this).addClass('active')
        $('.config__register-form-shop').fadeOut(function () {
            $('.config__register-form-customer').fadeIn();
        });
    })
});


